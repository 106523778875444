<template>
    <div class="page-coach-feedback-detail">
        <HeadBar left-arrow @click-left="newAppBack" title="反馈详情" />
        <div class="body">
            <div class="model">
                <div class="label">反馈时间</div>
                <div class="value">{{ dataObj.submitTime }}</div>
            </div>
            <div class="model">
                <div class="label">使用角色</div>
                <div class="value">{{ roleJson[dataObj.roleType] }}</div>
            </div>
            <div class="model">
                <div class="label">反馈内容</div>
                <div class="value">
                    <div class="d-qus">{{ dataObj.content }}</div>
                    <div class="imglist">
                        <div class="imgmodel" v-for="(item, index) in list" @click="showIMG(index)">
                            <img :src="item" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack } from '@/lib/appMethod'
import HeadBar from '@/components/app/headBar';
import { ImagePreview } from 'vant';
export default {
    mixins: [userMixin],
    components: {
        HeadBar
    },
    data() {
        return {
            list: [
                // 'https://img01.yzcdn.cn/vant/apple-1.jpg',
                // 'https://img01.yzcdn.cn/vant/apple-2.jpg',
                // 'https://img.chaolu.com.cn/mainImg/2024-07-30/1722333070.png',
                // 'https://img.chaolu.com.cn/mainImg/2024-09-05/1725515002.png',
                // 'https://img.chaolu.com.cn/mainImg/2024-06-06/1717668101.png'
            ],
            dataObj: {},
            roleJson: {
                1: '私教教练', 2: '团课教练', 3: '兼职教练', value: '3', 4: '管理角色'
            }
        }
    },
    async created() {
        await this.$getAllInfo(['teacherId', 'teacherToken']); console.log(this.teacherId)
        initBack();
        this.getData();
    },
    methods: {
        newAppBack,
        getData() {
            this.$axios.post(this.baseURLApp + "/teacher/feedback/get", {
                teacherFeedbackId: this.$route.query.id
            }).then((res) => {
                this.dataObj=res.data||{};
                this.list=res.data.imgList||[];
            })
        },
        showIMG(index) {
            ImagePreview({
                images: this.list,
                startPosition: index,
                closeable: true,
                closeIconPosition:'top-left',
                getContainer:'.page-coach-feedback-detail',
                closeIcon:'plus'
            });
        }
    },
}
</script>
<style lang="less" scoped>
.page-coach-feedback-detail {
    background: #fff;
    min-height: 100vh;

    div {
        box-sizing: border-box;
    }

    img {
        vertical-align: top;
        width: 100%;
    }

    .body {
        padding: 30px 32px;
    }

    .model {
        display: flex;
        margin-bottom: 64px;

        .label {
            // width: 112px;
            font-weight: 600;
            font-size: 28px;
            color: #000000;
            margin-right: 24px;
            line-height: 46px;
            flex-shrink: 0;
        }

        .value {
            flex: 1;
            font-size: 28px;
            color: #000000;
            line-height: 44px;

            .d-qus {
                line-height: 44px;
            }

            .imglist {
                display: flex;
                flex-wrap: wrap;
                width: 550px;
                .imgmodel {
                    margin-right: 20px;
                    width: 170px;
                    height: 170px;
                    border-radius: 8px;
                    overflow: hidden;
                    margin-top: 20px;

                    img {
                        height: 100%;
                        object-fit: cover;
                    }

                    &:nth-child(3n+3) {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    /deep/.van-image-preview__close-icon{
      transform: rotate(45deg);
    }
}
</style>